// @flow
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
// Components
import { Button, RadioButton } from '../../../common';
import CheckoutErrors from '../CheckoutErrors';
import DeliveryPicker from './DeliveryPicker';
import Takeout from './Takeout';
import PanelCard from '../PanelCard';
// Analytics
import * as Analytics from '../../../common/analytics';
// Types
import type { ShippingRate } from '../../types';
// Styles
import './DeliveryPane.css';

type Props = {
  errors: string[],
  loading: boolean,
  active: boolean,
  selected: ?ShippingRate,
  shippingRates: ShippingRate[],
  onSubmit: ShippingRate => void,
  dismissErrors: () => void,
  transitionToDelivery: () => void,
  isCompleted: boolean,
};

const DeliveryPane = (props: Props) => {
  const [selectedShippingRate, setSelectedShippingRate] =
    useState<ShippingRate | null>(
      props.shippingRates.length > 0 ? props.shippingRates[0] : null,
    );
  const [deliveryType, setDeliveryType] = useState<
    'entrega_agendada' | 'retirada',
  >('entrega_agendada');

  const submitShippingRate = (e: Event | undefined) => {
    e && e.preventDefault();
    if (selectedShippingRate) {
      props.onSubmit(selectedShippingRate);
    }
  };

  const selectShippingRate = (sr: ShippingRate) => {
    setSelectedShippingRate(sr);
  };

  const handleSelectDeliveryType = (
    type: string,
    takeoutShippingRate?: ShippingRate,
  ) => {
    const shippingRate = type === 'retirada' ? takeoutShippingRate : null;
    setSelectedShippingRate(shippingRate);
    setDeliveryType(type);
  };

  const takeoutShippingRate = props.shippingRates.find(
    s => s.shippingMethodCode === 'takeout',
  );
  const shippingRatesWithoutTakeout = props.shippingRates.filter(
    s => s.shippingMethodCode !== 'takeout',
  );

  useEffect(() => {
    if (
      props.shippingRates.length > 0 &&
      selectedShippingRate &&
      !_.find(props.shippingRates, {
        id: selectedShippingRate.id,
      })
    ) {
      setSelectedShippingRate(null);
    }
    // eslint-disable-next-line
  }, [props.shippingRates.length]);

  useEffect(() => {
    setSelectedShippingRate(null);
  }, [props.active]);

  return (
    <>
      <PanelCard
        isOpen={props.active}
        number={2}
        loading={props.loading}
        title="Agende sua entrega"
        handleClick={props.transitionToDelivery}
        isCompleted={props.isCompleted}
        isLoading={props.loading}
      >
        {props.active && (
          <div>
            <CheckoutErrors
              errors={props.errors}
              dismiss={props.dismissErrors}
            />
            {takeoutShippingRate && (
              <>
                <h3 className="delivery-pane-title">Opções de entrega</h3>
                <RadioButton
                  text={'Entrega agendada'}
                  isSelected={deliveryType === 'entrega_agendada'}
                  onSelect={() => handleSelectDeliveryType('entrega_agendada')}
                />

                <RadioButton
                  text={'Retirada'}
                  isSelected={deliveryType === 'retirada'}
                  onSelect={() =>
                    handleSelectDeliveryType('retirada', takeoutShippingRate)
                  }
                />
              </>
            )}

            {(deliveryType === 'entrega_agendada' || !takeoutShippingRate) && (
              <>
                <h3 className="delivery-pane-title">
                  Escolha a data e horário da entrega
                </h3>
                <DeliveryPicker
                  shippingRates={shippingRatesWithoutTakeout}
                  selected={selectedShippingRate}
                  onSelect={selectShippingRate}
                />
              </>
            )}
            <div className="delivery-pane-continue-container">
              {deliveryType === 'retirada' && (
                <Takeout takeoutShippingRate={takeoutShippingRate} />
              )}
              <div className="delivery-pane-checkout-continue-button">
                <Button
                  buttonType="button-primary"
                  icon=""
                  text="Confirmar data de entrega"
                  disabled={!selectedShippingRate}
                  onClick={(e: Event) => {
                    submitShippingRate(e);
                    Analytics.logConfirmedDelivery();
                  }}
                  loading={props.loading}
                />
              </div>
            </div>
          </div>
        )}
      </PanelCard>
    </>
  );
};

export default DeliveryPane;

// @flow
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
// Analytics
import * as Analytics from '../../../common/analytics';
// Components
import AddressForm from './AddressForm';
import PanelCard from '../PanelCard';
import CheckoutErrors from '../CheckoutErrors';
import AddressPicker from './AddressPicker';
import { Button, ConfirmationModal } from '../../../common';
// Types
import type { UserAddress } from '../../../user';
import type { OrderAddress } from '../../types';
import type { Address } from '../../../types/Address';
// Styles
import './AddressPane.css';
// Assets
import plusIcon from '../../images/plus.svg';

export type State = {
  editing: number | 'new' | false,
  address: ?UserAddress,
};

export type Props = {
  errors: string[],
  active: boolean,
  selected: ?OrderAddress,
  loading: boolean,
  onSubmit: (address: UserAddress) => void,
  dismissErrors: () => void,
  // Address Connector
  addresses: UserAddress[], // From connector
  addressesLoading: boolean, // From connector
  editingLoading: boolean, // From connector
  addingLoading: boolean, // From connector
  deletingLoading: (id: number) => boolean, // From connector
  onNew: (address: UserAddress) => void, // From connector
  onEdit: (address: UserAddress) => void, // From connector
  onDelete: (id: number) => void, // From connector
  transitionToAddress: () => void,
  isCompleted: boolean,
};

const defaultAddress: UserAddress = {
  id: null,
  baseAddressId: null,
  receiverName: '',
  addressLine1: '',
  addressLine2: '',
  neighborhood: '',
  latitude: null,
  longitude: null,
  city: '',
  zipcode: '',
  additionalInformation: '',
  reference: '',
  telephone: '',
  cnpj: '',
  company: '',
  residenceType: '',
  residenceNumber: '',
  residenceData: '',
  deliveryCost: '',
};

const AddressPane = (props: Props) => {
  const addressLengthRef = useRef();
  const isActiveRef = useRef();
  const [isEditing, setIsEditing] = useState(false);
  const [address, setAddress] = useState(defaultAddress);
  const [showModal, setShowModal] = useState(false);

  const handleAddresses = () => {
    const hasAnAddress = props.addresses.length > 0;
    if (!hasAnAddress) {
      setIsEditing(true);
      setAddress(defaultAddress);
      return;
    }
    setAddress(_.maxBy(props.addresses, 'id'));
  };

  useEffect(() => {
    isActiveRef.current = props.active;
    addressLengthRef.current = props.addresses.length;
    handleAddresses();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      addressLengthRef.current !== props.addresses.length ||
      (isActiveRef.current && !props.active && props.addresses.length > 0)
    ) {
      isActiveRef.current = props.active;
      addressLengthRef.current = props.addresses.length;
      const newestAddress = _.maxBy(props.addresses, 'id');
      setAddress(newestAddress);
      setIsEditing(false);
    }
    // eslint-disable-next-line
  }, [props.addresses?.length]);

  const newAddress = () => {
    setIsEditing(prevState => !prevState);
    setAddress(defaultAddress);
  };

  const pickAddress = (address: UserAddress) => {
    if (isEditing) {
      setIsEditing(false);
    }
    setAddress(address);
  };

  const editAddress = (address: UserAddress) => {
    setIsEditing(true);
    setAddress(address);
  };

  const updateAddress = (address: UserAddress) => {
    props.onEdit(address);
    setAddress(address);
    setIsEditing(false);
  };

  const deleteAddress = (address: UserAddress) => {
    Analytics.logDeleteAddress(address);
    // Dispatch the action that deletes the address
    props.onDelete(address.id);
  };

  const submitAddress = () => {
    if (address) props.onSubmit(address);
  };

  return (
    <PanelCard
      isOpen={props.active}
      handleClick={props.transitionToAddress}
      title="Endereço de entrega"
      number={1}
      isCompleted={props.isCompleted}
      isLoading={props.loading}
    >
      {props.active && (
        <div>
          <CheckoutErrors errors={props.errors} dismiss={props.dismissErrors} />
          <p>
            {props.addresses.length > 0
              ? 'Selecione um endereço existente ou clique em "adicionar novo endereço" e "Salvar". Depois, clique "confirmar entrega".'
              : 'Preencha o endereço, salve e depois clique "confirmar endereço".'}
          </p>
          {isEditing && (
            <AddressForm
              address={address}
              loading={props.addingLoading}
              onCancel={() => {
                setIsEditing(prevValue => !prevValue);
              }}
              onSubmitAddress={(addressValues: Address) => {
                if (addressValues.id) {
                  return updateAddress(addressValues);
                }
                props.onNew(addressValues);
              }}
              active={isEditing}
            />
          )}
          {!isEditing && (
            <>
              <AddressPicker
                addresses={props.addresses}
                selected={address}
                loading={props.loading}
                editing={isEditing}
                pickAddress={pickAddress}
                updateAddress={updateAddress}
                editAddress={editAddress}
                deleteAddress={address => {
                  setShowModal(true);
                  setAddress(address);
                }}
                editingLoading={props.editingLoading}
                deletingLoading={props.deletingLoading}
                addressesLoading={props.addressesLoading}
              />

              <button
                className="address-pane-checkout-continue-button"
                onClick={newAddress}
                disabled={isEditing}
              >
                <img src={plusIcon} alt="Ícone de mais" />
                Criar novo endereço
              </button>

              <Button
                onClick={() => {
                  submitAddress();
                  Analytics.logConfirmedAddress();
                }}
                buttonType="button-primary"
                disabled={!address || !address.id}
                text="Confirmar endereço"
                loading={props.loading}
              />
            </>
          )}

          <ConfirmationModal
            isOpen={showModal}
            text="Gostaria de excluir esse endereço?"
            onClose={() => {
              setShowModal(false);
            }}
            onConfirm={() => {
              setShowModal(false);
              deleteAddress(address);
            }}
          />
        </div>
      )}
    </PanelCard>
  );
};

export default AddressPane;

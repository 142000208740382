// @flow
import React from 'react';
// Styles
import './ResumePane.css';
// Components
import PanelCard from '../PanelCard';
import { Button } from '../../../common';
import ResumeItem from './ResumeItem';
import CheckoutErrors from '../CheckoutErrors';
import AddressSummary from '../address/AddressSummary';
import DeliverySummary from '../delivery/DeliverySummary';
import PaymentSummary from '../payment/PaymentSummary';
// Helpers
import { priceConverter } from '../../../common';
// Types
import type { LineItem } from '../types';
import type { OrderAddress, OrderDelivery, OrderPayment } from '../../types';

type Props = {
  orderNumber: string,
  errors: string[],
  selected: PaymentMethod,
  active: boolean,
  loading: boolean,
  submitConfirmation: () => void,
  adjustmentTotal: string,
  items: LineItem[],
  shipTotal: number,
  totalPrice: number,
  itemTotal: number,
  availableStoreCredits: string,
  appliedStoreCredits: string,
  bigClubCreditAmount: number,
  useBigClubCreditAmount: boolean,
  bigClubInfo: BigClubInfo,
  useBigClubCredit: boolean,
  isCompleted: boolean,
  dismissErrors: () => void,
  orderAddress: OrderAddress,
  orderDelivery: OrderDelivery,
  orderPayment: OrderPayment,
  transitionToAddress: () => void,
  transitionToDelivery: () => void,
  transitionToPayment: () => void,
};

const ResumePane = ({
  submitConfirmation,
  loading,
  active,
  items,
  adjustmentTotal,
  shipTotal,
  totalPrice,
  itemTotal,
  availableStoreCredits,
  appliedStoreCredits,
  bigClubCreditAmount,
  useBigClubCredit,
  bigClubInfo,
  isCompleted,
  errors,
  dismissErrors,
  orderAddress,
  orderDelivery,
  orderPayment,
  transitionToAddress,
  transitionToDelivery,
  transitionToPayment,
}: Props) => {
  const appliedOrAppliableStoreCredits = Math.max(
    Math.min(availableStoreCredits, totalPrice),
    appliedStoreCredits,
  );
  const isAppliableBigClubCredit =
    (useBigClubCredit || bigClubInfo?.applied > 0) &&
    bigClubInfo?.available > 0;

  return (
    <PanelCard
      isOpen={active}
      number={4}
      title="Revisar Pedido"
      handleClick={() => null}
      isCompleted={isCompleted}
    >
      <CheckoutErrors errors={errors} dismiss={dismissErrors} />
      <section className="resume-info">
        <div className="resume-info-wrapper">
          <div className="resume-info-address">
            <h3 className="resume-info-address-title">Endereço</h3>
            {orderAddress && <AddressSummary address={orderAddress} />}
          </div>
          <button className="resume-info-button" onClick={transitionToAddress}>
            Editar
          </button>
        </div>
        <div className="resume-info-wrapper">
          <div className="resume-info-delivery-content">
            <h3 className="resume-info-delivery-content-title">Agendamento</h3>
            {orderDelivery && <DeliverySummary delivery={orderDelivery} />}
          </div>
          <button className="resume-info-button" onClick={transitionToDelivery}>
            Editar
          </button>
        </div>
        <div className="resume-info-wrapper">
          <div className="resume-info-payment">
            <h3 className="resume-info-payment-title">Pagamento</h3>
            {orderPayment && <PaymentSummary payment={orderPayment} />}
          </div>
          <button className="resume-info-button" onClick={transitionToPayment}>
            Editar
          </button>
        </div>
      </section>
      <div className="resume-container">
        {!!items &&
          items.length > 0 &&
          items.map((product: LineItem, index) => (
            <ResumeItem
              key={index}
              productImage={
                product.variant?.images && product.variant.images[0]
              }
              productName={product.variant.name}
              productManufacturer={product.variant.manufacturer}
              productListPrice={product.variant.listPrice}
              productPrice={product.variant.price}
              productUnit={product.variant.unitForQuantity}
              productSlug={product.variant.slug}
              productId={product.variant.id}
              parentProductId={product.variant.productId}
              productTotalOnHand={product.variant.totalOnHand}
              defaultQuantity={product.variant.defaultQuantity}
              incrementQuantity={product.variant.incrementQuantity}
              inStock={product.variant.inStock}
              discontinued={product.variant.discontinued}
              analyticsViewSource="resume products"
              quantity={product.quantity}
            />
          ))}
      </div>
      <div className="resume-container-divisor" />
      <div className="resume-container-row">
        <span>Preço estimado</span>
        <span>{priceConverter(itemTotal)}</span>
      </div>
      <div className="resume-container-row">
        <span>Taxa de entrega</span>
        <span>{priceConverter(shipTotal)}</span>
      </div>

      {Number(adjustmentTotal) > 0 && (
        <div className="resume-container-row">
          <span>Desconto</span>
          <span>{priceConverter(parseFloat(Math.abs(adjustmentTotal)))}</span>
        </div>
      )}

      <div className="resume-container-row resume-container-row-alt">
        <span>
          {isAppliableBigClubCredit ||
          Number(appliedOrAppliableStoreCredits) > 0
            ? 'Subtotal'
            : 'Total'}
        </span>
        <span>{priceConverter(parseFloat(Math.abs(totalPrice)))}</span>
      </div>

      {isAppliableBigClubCredit && bigClubCreditAmount > 0 ? (
        <>
          <div className="resume-container-row">
            <span>Créditos BigClub</span>
            <span>
              {priceConverter(parseFloat(Math.abs(bigClubCreditAmount)))}
            </span>
          </div>
          <div className="resume-container-row resume-container-row-alt">
            <span>Total</span>
            <span>
              {priceConverter(
                parseFloat(Math.abs(totalPrice - bigClubCreditAmount)),
              )}
            </span>
          </div>
        </>
      ) : appliedOrAppliableStoreCredits &&
        Number(appliedOrAppliableStoreCredits) > 0 ? (
        <>
          <div className="resume-container-row">
            <span>Crédito</span>
            <span>
              {priceConverter(
                parseFloat(Math.abs(appliedOrAppliableStoreCredits)),
              )}
            </span>
          </div>
          <div className="resume-container-row resume-container-row-alt">
            <span>Total</span>
            <span>
              {priceConverter(
                parseFloat(
                  Math.abs(totalPrice - appliedOrAppliableStoreCredits),
                ),
              )}
            </span>
          </div>
        </>
      ) : null}

      <Button
        buttonType="button-primary"
        onClick={submitConfirmation}
        text="Confirmar compra"
        loading={loading}
      />
    </PanelCard>
  );
};

export default ResumePane;

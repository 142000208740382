/** @flow
 * CHECKOUT ERRORS
 * Component to display the current errors returned by the server during
 * the checkout process
 */
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
// Styles
import './CheckoutErrors.css';
// Logger
import { logException } from '../../logHelper';

type Props = {
  errors: string[],
  dismiss: () => void,
};

const CheckoutErrors = ({ errors, dismiss }: Props) => {
  const currentError = errors.length > 0 ? errors[errors.length - 1] : null;

  const parseError = (error: string) => {
    try {
      const parsedError = JSON.parse(error);
      let errorString = '';

      if (parsedError.error) {
        errorString += parsedError.error;
      }

      if (parsedError.errors) {
        const values = _.values(parsedError.errors);

        for (let i = 0; i < values.length; i++) {
          errorString += ` ${values[i]}`;
        }
      }

      return errorString;
    } catch (err) {
      logException(err);
      return error;
    }
  };

  if (!currentError) {
    return null;
  }

  return (
    <div className="checkout-error-main">
      <div className="checkout-error alert alert-danger" role="alert">
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={dismiss}
        >
          <span aria-hidden="true">×</span>
        </button>
        <ul>
          <li>{parseError(currentError)}</li>
        </ul>
      </div>
    </div>
  );
};

export default CheckoutErrors;
